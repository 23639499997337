import React from 'react'

;
import { Amoeba } from './components/amoeba'
import styled from 'styled-components';

const Container = styled.div`
  
`;

function App() {

  return (    
    <Container>

      <Amoeba />

    </Container>
  );
}

export default App;
