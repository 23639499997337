// bookshelf-app/src/components/bookshelf.tsx

// Import deps
import React, { useState } from 'react'
import logo from '../GameDevSessions.png'
import locked from '../locked.png'
import unlocked from '../unlocked.png'
import poweredBy from '../powered by.png'
import styled from 'styled-components';
const Container = styled.div`
  color: white;
`;
const Title = styled.div`
  color: white;
  margin: 0 auto;
  margin-top: 20px;
  text-align: center;
  max-width: 230px;
  svg {
    display: block;
    
  }
`;
const LockText = styled.div`
  font-size: 16px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;
const Location = styled.a`
  display: block;
  font-size: 16px;
  text-align: center;
  color: white;
  margin-top: 10px;
`;
const PoweredBy = styled.img`
  display: block;
  width: 130px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const Logo = styled.img`
  display: block;
  width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 20px;
`;

const LockImage = styled.img`
  display: block;
  width: 180px;
  margin: 0 auto;
  margin-bottom: 20px;
`;
const Spinner = styled.div`
    display: block;
    position: absolute;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
    width: 80px;
    height: 80px;
  
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;
const SpinnerContainer = styled.div`
    position: relative;
    width: 180px;
    height: 180px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const SpinnerSvg = styled.svg`
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
`;

// Create Bookshelf component
export const Amoeba = () => {
  // Prepare states
    const [doorState, setDoorState] = useState('locked')
    function openDoor() {
        setDoorState('opening');
        fetch("/api/open").then(function () {
            setDoorState('unlocked');
        })
    }
    function resetState() {
        setDoorState('locked')
    }
    let LockState = <div/>
    let LockTexts = [];
    if (doorState === 'locked') {
        LockState = <LockImage onClick={openDoor} src={locked}/>
        LockTexts = ["Klicka på nyckeln för att låsa upp", "Click on key to unlock"]

    } else if(doorState === 'unlocked') {
      LockState = <LockImage onClick={resetState} src={unlocked}/>
        LockTexts = ["Välkommen in", "Welcome inside"]
    } else if(doorState === 'opening') {
      LockState = <SpinnerContainer>
          <SpinnerSvg
              width="523.5px" height="523.5px" viewBox={"0 0 523.5 523.5"}>
              <path fillRule="evenodd"  stroke="rgb(255, 255, 255)" strokeWidth="17px" strokeLinecap="butt" strokeLinejoin="miter" fill="none"
                    d="M257.499,17.499 C390.48,17.499 497.500,124.951 497.500,257.500 C497.500,390.48 390.48,497.499 257.499,497.499 C124.951,497.499 17.499,390.48 17.499,257.500 C17.499,124.951 124.951,17.499 257.499,17.499 Z"/>
          </SpinnerSvg>
          <Spinner><div/><div/><div/><div/></Spinner>
      </SpinnerContainer>
        LockTexts = ["Låser Upp...", "Unlocking..."]
    }

    return (
    <Container>
        <Title>
            <svg viewBox="0 0 328.82 51.02" style={{enableBackground: "new 0 0 328.82 51.02;"}} fill={"#ffffff"}>
                <g>
                    <path className="st0" d="M22.67,36.85c-2.26,2.21-4.66,3.36-7.87,3.36c-6.48,0-12.15-5.57-12.15-13.39c0-7.97,5.38-13.44,12-13.44
		c3.07,0,5.71,1.15,8.02,3.46v-2.78h6.38v25.49h-6.38V36.85z M9.08,26.77c0,4.37,2.88,7.58,6.86,7.58c3.94,0,7.01-2.98,7.01-7.63
		c0-4.46-2.98-7.49-7.01-7.49C11.72,19.23,9.08,22.59,9.08,26.77z"/>
                    <path className="st0" d="M55.02,39.54h-6.38V27.2c0-4.94-0.96-7.97-4.51-7.97c-3.17,0-5.47,2.59-5.47,7.82v12.48h-6.38V14.05h6.38v2.93
		c2.16-2.35,4.94-3.6,7.97-3.6c3.17,0,5.86,1.49,7.44,4.56c2.35-2.98,4.7-4.56,8.54-4.56c2.83,0,5.38,1.15,6.82,2.98
		c1.68,2.11,1.87,4.9,1.87,8.26v14.93h-6.38V25.47c0-4.18-1.49-6.24-4.37-6.24c-3.22,0-5.52,2.5-5.52,7.73V39.54z"/>
                    <path className="st0" d="M99.66,26.77c0,7.39-5.71,13.44-13.49,13.44c-7.39,0-13.25-5.9-13.25-13.39c0-7.73,6.24-13.44,13.2-13.44
		C93.56,13.38,99.66,19.23,99.66,26.77z M79.35,26.77c0,4.32,2.74,7.39,6.87,7.39c3.94,0,7.01-2.88,7.01-7.34
		c0-4.32-2.83-7.39-7.01-7.39C82.23,19.42,79.35,22.54,79.35,26.77z"/>
                    <path className="st0" d="M126.15,34.59c-2.74,3.89-6.34,5.62-11.71,5.62c-8.11,0-13.34-5.95-13.34-13.44c0-6.96,5.04-13.39,13.25-13.39
		c8.06,0,13.54,5.76,13.54,13.83c0,0.58-0.05,0.86-0.05,1.44h-20.55c0.62,3.79,3.5,5.9,7.15,5.9c2.74,0,4.56-0.77,6.34-2.5
		L126.15,34.59z M121.45,23.6c-0.86-2.98-3.6-4.8-7.01-4.8c-3.17,0-5.62,1.58-6.86,4.8H121.45z"/>
                    <path className="st0" d="M136.9,39.54h-6.38V4.21h6.38v12.62c2.3-2.3,4.94-3.46,8.02-3.46c6.58,0,12.05,5.47,12.05,13.44
		c0,7.82-5.71,13.39-12.19,13.39c-3.22,0-5.57-1.15-7.87-3.36V39.54z M136.62,26.72c0,4.61,3.07,7.63,7.01,7.63
		c3.98,0,6.91-3.22,6.91-7.58c0-4.18-2.69-7.54-6.91-7.54C139.55,19.23,136.62,22.26,136.62,26.72z"/>
                    <path className="st0" d="M178.57,36.85c-2.26,2.21-4.66,3.36-7.87,3.36c-6.48,0-12.14-5.57-12.14-13.39c0-7.97,5.38-13.44,12-13.44
		c3.07,0,5.71,1.15,8.02,3.46v-2.78h6.38v25.49h-6.38V36.85z M164.99,26.77c0,4.37,2.88,7.58,6.86,7.58c3.94,0,7.01-2.98,7.01-7.63
		c0-4.46-2.98-7.49-7.01-7.49C167.62,19.23,164.99,22.59,164.99,26.77z"/>
                    <path className="st0" d="M203.67,28.5c-4.08-2.06-6.14-4.56-6.14-7.78c0-3.98,3.36-7.34,8.26-7.34c3.31,0,6.34,1.49,8.4,4.22
		l-3.94,3.98c-1.44-1.44-2.88-2.35-4.42-2.35c-1.3,0-2.3,0.53-2.3,1.54c0,0.96,0.91,1.39,2.3,2.11l2.35,1.2
		c4.22,2.16,6.48,4.37,6.48,8.16c0,4.56-3.6,7.97-9.17,7.97c-3.7,0-6.72-1.49-8.93-4.37l3.94-4.32c1.49,1.73,3.65,2.98,5.33,2.98
		c1.58,0,2.83-0.91,2.83-2.06c0-1.1-1.06-1.92-2.83-2.83L203.67,28.5z"/>
                    <path className="st0" d="M223.78,16.83c2.3-2.3,4.94-3.46,8.02-3.46c6.58,0,12.05,5.47,12.05,13.44c0,7.82-5.71,13.39-12.19,13.39
		c-3.22,0-5.57-1.15-7.87-3.36v12h-6.38v-34.8h6.38V16.83z M223.49,26.72c0,4.61,3.07,7.63,7.01,7.63c3.98,0,6.91-3.22,6.91-7.58
		c0-4.18-2.69-7.54-6.91-7.54C226.42,19.23,223.49,22.26,223.49,26.72z"/>
                    <path className="st0" d="M265.45,36.85c-2.26,2.21-4.66,3.36-7.87,3.36c-6.48,0-12.14-5.57-12.14-13.39c0-7.97,5.38-13.44,12-13.44
		c3.07,0,5.71,1.15,8.02,3.46v-2.78h6.38v25.49h-6.38V36.85z M251.86,26.77c0,4.37,2.88,7.58,6.86,7.58c3.94,0,7.01-2.98,7.01-7.63
		c0-4.46-2.98-7.49-7.01-7.49C254.5,19.23,251.86,22.59,251.86,26.77z"/>
                    <path className="st0" d="M299.53,34.88c-2.74,3.55-6.58,5.33-11.57,5.33c-8.69,0-14.3-5.9-14.3-13.35c0-7.63,5.95-13.49,14.4-13.49
		c4.99,0,9.22,2.06,11.76,5.81l-5.33,2.93c-1.82-1.92-3.6-2.88-6.38-2.88c-4.9,0-8.02,3.31-8.02,7.73c0,4.32,3.02,7.39,7.73,7.39
		c2.83,0,5.09-0.96,6.67-2.93L299.53,34.88z"/>
                    <path className="st0" d="M324.63,34.59c-2.74,3.89-6.34,5.62-11.71,5.62c-8.11,0-13.34-5.95-13.34-13.44c0-6.96,5.04-13.39,13.25-13.39
		c8.06,0,13.54,5.76,13.54,13.83c0,0.58-0.05,0.86-0.05,1.44h-20.55c0.62,3.79,3.5,5.9,7.15,5.9c2.74,0,4.56-0.77,6.34-2.5
		L324.63,34.59z M319.93,23.6c-0.86-2.98-3.6-4.8-7.01-4.8c-3.17,0-5.62,1.58-6.86,4.8H319.93z"/>
                </g>
            </svg>
        </Title>
        <SubTitle>Tredje Långgatan 13B, 413 03 Göteborg</SubTitle>
        <Location href="http://maps.google.com/?q=Tredje Långgatan 13B, 413 03 Göteborg, Sweden" target="_blank" rel={"noopener"}>Hitta hit ></Location>
        <Logo src={logo}/>



        {LockState}
        {LockTexts.map(it => <LockText>{it}</LockText>)}

        <PoweredBy src={poweredBy}/>



    </Container>
    )
}